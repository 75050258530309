import React from 'react'
import settingsSelectors from '../../../buyer/shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import FileUploadStep4 from '../../components/FileUploadTab/FileUploadStep4'
import { connect } from 'react-redux'
import { uploadClientFile } from '../../actions'
import RootState from 'shared/models/RootState'
import Main from 'shared/components/Layout/Main'
import Page from 'shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'
import FileUploadTitle from 'admin/components/FileUploadTab/FileUploadTitle'
import {
  BusinessContextAnswersType,
  DataQualityAnswersType,
  UploadFileKind
} from 'admin/components/FileUploadTab/FileUploadTab'

type Props = {
  buyerType: string
  isBuyDataAdmin: boolean
  isUploading: boolean
  uploadClientFile: ({ file, questionnaireResponses, history }) => void
  dataQualityAnswers: DataQualityAnswersType
  businessContextAnswers: BusinessContextAnswersType
  uploadFileKind: UploadFileKind
}

const title = <FormattedMessage id='App.Upload' defaultMessage='Upload' />

const FileUploadStep4Container = ({
  buyerType,
  isBuyDataAdmin,
  isUploading,
  uploadClientFile,
  dataQualityAnswers,
  businessContextAnswers,
  uploadFileKind
}: Props) => {
  return (
    <Page title={title}>
      <FileUploadTitle />
      <Main>
        <FileUploadStep4
          buyerType={buyerType}
          isBuyDataAdmin={isBuyDataAdmin}
          isUploading={isUploading}
          onUploadClientFile={uploadClientFile}
          dataQualityAnswers={dataQualityAnswers}
          businessContextAnswers={businessContextAnswers}
          uploadFileKind={uploadFileKind}
        />
      </Main>
    </Page>
  )
}
export default connect(
  (state: RootState) => {
    return {
      buyerType: settingsSelectors.getBuyerType(state),
      isBuyDataAdmin: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
      isUploading: state.getIn(['admin', 'upload', 'isUploading'])
    }
  },
  {
    uploadClientFile
  }
)(FileUploadStep4Container)
