import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import paths from '../../routes/paths'
import FileUploadMainPage from './FileUploadMainPage'
import FileUploadStep1 from './FileUploadStep1'
import FileUploadStep2 from './FileUploadStep2'
import FileUploadStep3 from './FileUploadStep3'
import FileUploadStep4Container from 'admin/containers/FileUploadStep4Container'

export type UploadFileKind = {
  isSpendFileIncluded: boolean,
  modified: boolean
}
export type DataQualityAnswersType = {
  isCorrectFormat: boolean
  isProvidedForNewSuppliers: boolean
  isAddressProvided: boolean
  isIdProvided: boolean
  isCompanyNameProvided: boolean
  isTaxIdProvided: boolean
  isSpendInUSD: boolean
  modified: boolean
}
export type BusinessContextAnswersType = {
  refreshOrReset: string
  dateRangeForData: string
  dateRangeForDeletion: string
  spendCategory: string
  businessCategory: string
  modified:boolean
}

const FileUploadTab = () => {
  const [uploadFileKind, setUploadFileKind] = useState<
    UploadFileKind
    >({
      isSpendFileIncluded: false,
      modified: false
    })
  const [dataQualityAnswers, setDataQualityAnswers] = useState<
    DataQualityAnswersType
  >({
    isCorrectFormat: false,
    isProvidedForNewSuppliers: false,
    isAddressProvided: false,
    isIdProvided: false,
    isCompanyNameProvided: false,
    isTaxIdProvided: false,
    isSpendInUSD: false,
    modified: false
  })
  const [businessContextAnswers, setBusinessContextAnswers] = useState<
    BusinessContextAnswersType
  >({
    refreshOrReset: '',
    dateRangeForData: '',
    dateRangeForDeletion: '',
    spendCategory: '',
    businessCategory: '',
    modified: false
  })

  return (
    <Switch>
      <Route path={paths.fileUpload} exact component={FileUploadMainPage} />
      <Route path={paths.fileUploadStep1}>
        <FileUploadStep1 onSaveAnswers={setUploadFileKind}/>
      </Route>
      <Route path={paths.fileUploadStep2}>
        <FileUploadStep2 onSaveAnswers={setDataQualityAnswers} uploadFileKind={uploadFileKind}/>
      </Route>
      <Route path={paths.fileUploadStep3}>
        <FileUploadStep3 onSaveAnswers={setBusinessContextAnswers} isPreviousAnsPresent={dataQualityAnswers.modified}/>
      </Route>
      <Route path={paths.fileUploadLastStep}>
        <FileUploadStep4Container
          dataQualityAnswers={dataQualityAnswers}
          businessContextAnswers={businessContextAnswers}
          uploadFileKind={uploadFileKind}
        />
      </Route>

      <Redirect to={paths.fileUploadStep1} />
    </Switch>
  )
}

export default FileUploadTab
