import React, { useMemo, useState } from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import paths from 'admin/routes/paths'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Grid } from '@material-ui/core'
import Button from 'shared/components/Button'
import FileUploadTitle from './FileUploadTitle'
import { UploadFileKind } from './FileUploadTab'

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  button: {
    float: 'right',
    marginTop: '6px'
  },
  templateLink: {
    '&:hover, &:focus': {
      color: '#002525'
    },
    '&:focus': {
      outline: 'none'
    }
  }
}))

type Props = {
  onSaveAnswers: (answers: UploadFileKind) => void
}

const FileUploadStep1 = (props: Props) => {
  const history = useHistory()
  const classes = useStyles()

  const [fileType1, setFileType1] = useState<boolean>(false)
  const [fileType2, setFileType2] = useState<boolean>(false)

  const oneOptionIsChecked = useMemo(() => {
    return fileType1 || fileType2
  },[fileType1, fileType2])

  const handleFileType1Select = () => {
    setFileType2(false)
    setFileType1(!fileType1)
  }

  const handleFileType2Select = () => {
    setFileType1(false)
    setFileType2(!fileType2)
  }

  const handleClickNextButton = () => {
    const isSpendFileIncluded = fileType2
    props.onSaveAnswers({isSpendFileIncluded, modified: true})
    history.push(paths.fileUploadStep2)
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='FileUploadStep1.Title'
          defaultMessage='Upload your file'
        />
      }
    >
      <FileUploadTitle />
      <Paper>
        <div className='mt3'>
          <Label>
            <FormattedMessage
              id='FileUploadStep1.Description'
              defaultMessage='What kind of file are you uploading?'
            />
          </Label>
        </div>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justify='space-between'
          className='mt4'
        >
          <Grid item xs>
            <FormControlLabel
              label={
                <FormattedMessage
                  id='FileUploadStep1.option1'
                  defaultMessage={`Vendor master only (no spend)`}
                />
              }
              control={
                <Checkbox
                  color='primary'
                  name='option1'
                  checked={fileType1}
                  onChange={handleFileType1Select}
                />
              }
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              label={<FormattedMessage
                id='FileUploadStep1.option2'
                defaultMessage={`Vendor master with spend`}
              />}
              control={
                <Checkbox
                  color='primary'
                  name='q5'
                  checked={fileType2}
                  onChange={handleFileType2Select}
                />
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <Button
        className={classes.button}
        onClick={() => handleClickNextButton()}
        label={<FormattedMessage id='FileUpload.Next' defaultMessage='Next' />}
        disabled={!oneOptionIsChecked}
      />
    </Page>
  )
}

export default FileUploadStep1
