import React from 'react'
import { Column } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import startCase from 'lodash.startcase'

import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'

import { PartnerCustomerReport } from './InternalStats'

type Props = {
  stat: string
  value: List<PartnerCustomerReport>
}
const PartnerCustomerReportTable = ({ stat, value }: Props) => {
  return (
    <div key={stat}>
      <div className='flex justify-between items-center mt4'>
        <InsightTitle>{startCase(stat)}</InsightTitle>
        <Button
          onClick={() => {
            exportData.exportCSV(
              value.toJS(),
              'PartnerCustomerReport',
              true
            )
          }}
        >
          <FormattedMessage id='InternalStats.Export' defaultMessage='Export' />
        </Button>
      </div>
      <div className='bg-white br1 ba b--black-10'>
        <Table
          rowGetter={({ index }) => value && !!value.size && value.get(index)}
          rowCount={value.size}
        >
          <Column
            label={
              <FormattedMessage
                id='InternalStats.partner'
                defaultMessage='Partner'
              />
            }
            width={150}
            dataKey='partnerName'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.clientName'
                defaultMessage='Partner Customer Name'
              />
            }
            width={150}
            dataKey='clientName'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.clientId'
                defaultMessage='Partner Customer ID'
              />
            }
            width={150}
            dataKey='clientId'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.lastApiDate'
                defaultMessage='Last API usage date'
              />
            }
            width={150}
            dataKey='lastApiDate'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.lastApi'
                defaultMessage='Last API called'
              />
            }
            width={150}
            dataKey='lastApi'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.apisUsed'
                defaultMessage='APIs used'
              />
            }
            width={150}
            dataKey='apisUsed'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.avgDaily'
                defaultMessage='Daily API Usage'
              />
            }
            width={150}
            dataKey='avgDaily'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.avgMonthly'
                defaultMessage='Monthly API usage'
              />
            }
            width={150}
            dataKey='avgMonthly'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.connectedSuppliers'
                defaultMessage='No. of connected suppliers'
              />
            }
            width={150}
            dataKey='connectedSuppliers'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.totalAPICalls'
                defaultMessage='Total APIs calls'
              />
            }
            width={150}
            dataKey='totalAPICalls'
          /> 
        <Column
          label={
            <FormattedMessage
              id='InternalStats.totalSuppliersSearched'
              defaultMessage='Total Suppliers Searched'
            />
          }
          width={150}
          dataKey='totalSuppliersSearched'
        />
        <Column
          label={
            <FormattedMessage
              id='InternalStats.totalUniqueSuppliersSearched'
              defaultMessage='Total Unique Suppliers Searched'
            />
          }
          width={150}
          dataKey='totalUniqueSuppliersSearched'
        />
        </Table>
      </div>
    </div>
  )
}

export default PartnerCustomerReportTable
