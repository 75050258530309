import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import Label from 'shared/components/Label'
import paths from 'admin/routes/paths'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import DateRangeSelect from 'shared/components/DateRangeSelect'
import { DateRange } from 'materialui-daterange-picker'
import FileUploadTitle from './FileUploadTitle'
import { defineMessages } from 'react-intl'
import * as fns from 'date-fns'
import { BusinessContextAnswersType } from './FileUploadTab'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'

export const messages = defineMessages({
  refreshOrReset: {
    id: 'FileUploadStep3.refreshOrReset',
    defaultMessage:
      'Is this a quarterly refresh (new data only) or re-set of data (deletion of previously provided data & replacement)?'
  },
  dateRangeForData: {
    id: 'FileUploadStep3.dateRangeForData',
    defaultMessage: 'Please provide the date range for data provided'
  },
  dateRangeForDeletion: {
    id: 'FileUploadStep3.dateRangeForDeletion',
    defaultMessage:
      'Should any previously provided data be deleted? If so, please provide the date range for deletion (if applicable)'
  },
  spendCategory: {
    id: 'FileUploadStep3.spendCategory',
    defaultMessage:
      'If you provided data in the category columns, which column do you want to include in spend category? (optional)'
  },
  businessCategory: {
    id: 'FileUploadStep3.businessCategory',
    defaultMessage:
      'If you provided data in the category columns, which column do you want to include in business units? (optional)'
  },
  missingResponse: {
    id: 'FileUploadStep3.missingResponse',
    defaultMessage: 'Responses are missing'
  }
})

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  button: {
    float: 'right',
    marginTop: '6px'
  }
}))
type Props = {
  onSaveAnswers: (answers: BusinessContextAnswersType) => void
  isPreviousAnsPresent: boolean
}

const FileUploadStep3 = (props: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const intl = useIntl()
  const tier1 = 'Category Tier 1'
  const tier2 = 'Category Tier 2'
  const dispatch = useDispatch()


  useEffect(() => {
    if((!props.isPreviousAnsPresent)) {
      dispatch(
        notify({
          message: intl.formatMessage(messages.missingResponse)
        })
      )
      history.push(paths.fileUpload)
    }
  }, [dispatch, history, intl, props.isPreviousAnsPresent])

  const [refreshOrReset, setRefreshOrReset] = useState<string>('')
  const [dateRangeForData, setDateRangeForData] = useState<DateRange>({})
  const [dateRangeForDeletion, setDateRangeForDeletion] = useState<DateRange>(
    {}
  )
  const [spendCategory, setSpendCategory] = useState<string>('')
  const [businessCategory, setBusinessCategory] = useState<string>('')

  const allQuestionsAnswered = useMemo(() => {
    return (
      refreshOrReset && dateRangeForData.startDate && dateRangeForData.endDate
    )
  }, [refreshOrReset, dateRangeForData])

  const formatDateRange = (dateRange: DateRange) => {
    const format = 'MM/DD/YYYY'
    const startDate = dateRange?.startDate
      ? fns.format(dateRange.startDate, format)
      : undefined

    const endDate = dateRange?.endDate
      ? fns.format(dateRange.endDate, format)
      : undefined
    return startDate || endDate ? `${startDate} - ${endDate}` : 'No'
  }

  const handleClickNextButton = () => {
    history.push(paths.fileUploadLastStep)

    const answers = {
      refreshOrReset,
      dateRangeForData: formatDateRange(dateRangeForData),
      dateRangeForDeletion: formatDateRange(dateRangeForDeletion),
      spendCategory,
      businessCategory,
      modified: true
    }
    props.onSaveAnswers(answers)
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='FileUploadStep3.Title'
          defaultMessage='Upload your file'
        />
      }
    >
      <FileUploadTitle />
      <Paper>
        <div className='mt3'>
          <Label>
            <FormattedMessage
              id='FileUploadStep3.Description'
              defaultMessage='Please provide some more information about your file'
            />
          </Label>
        </div>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justify='space-between'
          className='mt2'
          spacing={2}
        >
          <Grid item xs>
            <Label htmlFor='refreshOrReset'>
              {intl.formatMessage(messages.refreshOrReset)}
            </Label>
            <Select
              name='refreshOrReset'
              value={refreshOrReset}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setRefreshOrReset(e.target.value)
              }}
            >
              <FormattedMessage
                id='FileUploadStep3.selectAnOption'
                defaultMessage='Select an option'
              >
                {message => (
                  <option value='' disabled>
                    {message}
                  </option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Refresh'
                defaultMessage='Refresh'
              >
                {message => <option value='Refresh'>{message}</option>}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Reset'
                defaultMessage='Re-set'
              >
                {message => <option value='Re-set'>{message}</option>}
              </FormattedMessage>
            </Select>
            <br />

            <Label htmlFor='dateRangeForData'>
              {intl.formatMessage(messages.dateRangeForData)}
            </Label>
            <DateRangeSelect
              dateRange={dateRangeForData}
              onDateRangeChange={setDateRangeForData}
              id='dateRangeForData'
            />
            <br />

            <Label htmlFor='dateRangeForDeletion'>
              {intl.formatMessage(messages.dateRangeForDeletion)}
            </Label>
            <DateRangeSelect
              dateRange={dateRangeForDeletion}
              onDateRangeChange={setDateRangeForDeletion}
              id='dateRangeForDeletion'
            />
          </Grid>

          <Grid item xs>
            <Label htmlFor='spendCategory'>
              {intl.formatMessage(messages.spendCategory)}
            </Label>
            <Select
              name='spendCategory'
              value={spendCategory}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setSpendCategory(e.target.value)
              }}
            >
              <FormattedMessage
                id='FileUploadStep3.selectAnOption'
                defaultMessage='Select an option'
              >
                {message => (
                  <option value='' >
                    {message}
                  </option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Tier1'
                defaultMessage={tier1}
              >
                {message => <option value={tier1} disabled={businessCategory === tier1}>{message}</option>}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Tier2'
                defaultMessage={tier2}
              >
                {message => <option value={tier2} disabled={businessCategory === tier2}>{message}</option>}
              </FormattedMessage>
            </Select>
            <br />

            <Label htmlFor='businessCategory'>
              {intl.formatMessage(messages.businessCategory)}
            </Label>
            <Select
              name='businessCategory'
              value={businessCategory}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setBusinessCategory(e.target.value)
              }}
            >
              <FormattedMessage
                id='FileUploadStep3.selectAnOption'
                defaultMessage='Select an option'
              >
                {message => (
                  <option value='' >
                    {message}
                  </option>
                )}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Tier1'
                defaultMessage={tier1}
              >
                {message => <option value={tier1} disabled={spendCategory === tier1}>{message}</option>}
              </FormattedMessage>
              <FormattedMessage
                id='FileUploadStep3.Tier2'
                defaultMessage={tier2}
              >
                {message => <option value={tier2} disabled={spendCategory === tier2}>{message}</option>}
              </FormattedMessage>
            </Select>
          </Grid>
        </Grid>
      </Paper>
      <Button
        className={classes.button}
        onClick={() => handleClickNextButton()}
        label={<FormattedMessage id='FileUpload.Next' defaultMessage='Next' />}
        disabled={!allQuestionsAnswered}
      />
    </Page>
  )
}

export default FileUploadStep3
