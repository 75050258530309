import { put, call } from 'redux-saga/effects'
import { getOrgSettings } from '../../../buyer/shared/actions'
import {
  loadInsights,
  loadConsolidation
} from '../../../buyer/Insights/store/actions'
import { GET_SPEND_YEARS } from '../../../buyer/Insights/store/actionTypes'
import {
  loadSupplierInsight,
  loadCompany,
  loadVets
} from '../../../supplier/shared/actions'
import insight from 'shared/utils/api/insight'
import { requestSuccess } from 'shared/actionTypes'
import { loadData } from 'shared/actions'
import { resultParser } from '../../../supplier/Target/Target'
import { loadCommunities } from '../../../supplier/Communities/actions'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { loadPosts } from '../../../posting/actions'
import { getTier2Communities } from '../../../supplier/Insight/store/actions'
import {
  loadAllSurvey,
  loadAllSurveyInvitations
} from '../../../supplier/Survey/actions'

export function* preloadSaga(action) {
  let hasAccessToBuyerApp = false
  let hasAccessToSupplierApp = false

  const { userInfo } = action.payload
  if (userInfo && userInfo.roles && userInfo.roles.length > 0) {
    hasAccessToBuyerApp = userInfo.roles.some(r => r === 'buyer')
    hasAccessToSupplierApp = userInfo.roles.some(r => r === 'supplier')
  }

  if (hasAccessToBuyerApp || hasAccessToSupplierApp) {
    yield put(loadPosts())
  }

  if (hasAccessToBuyerApp) {
    yield put(getOrgSettings())
    yield put(loadConsolidation())
    yield put(loadInsights({ interval: 90 }))
    const response = yield call(insight.getBuyerSpendYears)
    yield put({
      type: requestSuccess(GET_SPEND_YEARS),
      payload: response
    })
  }

  if (hasAccessToSupplierApp) {
    yield put(loadCompany())
    yield put(loadAllSurvey())
    yield put(loadVets())
    yield put(loadSupplierInsight())
    yield put(
      loadData({
        entity: 'targetClients',
        query: 'supplier/target',
        resultParser
      })
    )
    yield put(loadCommunities())
    yield put(getTier2Communities())
    yield put(loadAllSurveyInvitations())
  }
}

export default createApiRequestSaga(preloadSaga)
