import React, { useEffect, useMemo, useState } from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import Label from 'shared/components/Label'
import paths from 'admin/routes/paths'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Grid } from '@material-ui/core'
import ExternalLink from 'shared/components/ExternalLink'
import Button from 'shared/components/Button'
import FileUploadTitle from './FileUploadTitle'
import { defineMessages } from 'react-intl'
import { DataQualityAnswersType, UploadFileKind } from './FileUploadTab'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'

export const messages = defineMessages({
  isCorrectFormat: {
    id: 'FileUploadStep2.isCorrectFormat',
    defaultMessage:
      'Data has been provided in the correct format via TealBook Vendor Master Template?'
  },
  isProvidedForNewSuppliers: {
    id: 'FileUploadStep2.isProvidedForNewSuppliers',
    defaultMessage:
      'Updated Vendor Master information has been provided for new suppliers?'
  },
  isAddressProvided: {
    id: 'FileUploadStep2.isAddressProvided',
    defaultMessage:
      'Physical addresses (Street Address, City, Zipcode, Country) have been provided?'
  },
  isIdProvided: {
    id: 'FileUploadStep2.isIdProvided',
    defaultMessage: 'Internal Supplier ID or Vendor ID provided?'
  },
  isCompanyNameProvided: {
    id: 'FileUploadStep2.isCompanyNameProvided',
    defaultMessage: 'Supplier Company name provided?'
  },
  isTaxIdProvided: {
    id: 'FileUploadStep2.isTaxIdProvided',
    defaultMessage: 'Tax ID provided? (optional)'
  },
  isSpendInUSD: {
    id: 'FileUploadStep2.isSpendInUSD',
    defaultMessage: 'All spend has been converted to USD?'
  },
  vendorMasterTemplate: {
    id: 'FileUploadStep2.VendorMasterTemplate',
    defaultMessage: 'Vendor Master Template'
  },
  vendorMasterTemplateWithQuestionMark: {
    id: 'FileUploadStep2.vendorMasterTemplateWithQuestionMark',
    defaultMessage: 'Vendor Master Template?'
  },
  missingResponse: {
    id: 'FileUploadStep2.missingResponse',
    defaultMessage: 'Responses are missing'
  }
})

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  button: {
    float: 'right',
    marginTop: '6px'
  },
  templateLink: {
    '&:hover, &:focus': {
      color: '#002525'
    },
    '&:focus': {
      outline: 'none'
    }
  }
}))

type Props = {
  onSaveAnswers: (answers: DataQualityAnswersType) => void
  uploadFileKind: UploadFileKind
}

const FileUploadStep2 = (props: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [isCorrectFormat, setIsCorrectFormat] = useState<boolean>(false)
  const [isProvidedForNewSuppliers, setIsProvidedForNewSuppliers] = useState<
    boolean
  >(false)
  const [isAddressProvided, setIsAddressProvided] = useState<boolean>(false)
  const [isIdProvided, setIsIdProvided] = useState<boolean>(false)
  const [isCompanyNameProvided, setIsCompanyNameProvided] = useState<boolean>(
    false
  )
  const [isTaxIdProvided, setIsTaxIdProvided] = useState<boolean>(false)
  const [isSpendInUSD, setIsSpendInUSD] = useState<boolean>(false)
  const isSpendFileIncluded:boolean = props.uploadFileKind.isSpendFileIncluded
  const isPreviousAnsPresent:boolean = props.uploadFileKind.modified

  const allRequiredQuestionsChecked = useMemo(() => {
    return (
      isCorrectFormat &&
      isProvidedForNewSuppliers &&
      isAddressProvided &&
      isIdProvided &&
      isCompanyNameProvided && (
      isSpendInUSD || !isSpendFileIncluded)
    )
  }, [
    isCorrectFormat,
    isProvidedForNewSuppliers,
    isAddressProvided,
    isIdProvided,
    isCompanyNameProvided,
    isSpendInUSD,
    isSpendFileIncluded
  ])

  const handleClickNextButton = () => {
    if (isSpendFileIncluded) {
      history.push(paths.fileUploadStep3)
    }
    else {
      history.push(paths.fileUploadLastStep , {isSpendFileIncluded: isSpendFileIncluded})
    }

    const answers = {
      isCorrectFormat,
      isProvidedForNewSuppliers,
      isAddressProvided,
      isIdProvided,
      isCompanyNameProvided,
      isTaxIdProvided,
      isSpendInUSD,
      modified: true
    }
    props.onSaveAnswers(answers)
  }

  useEffect(() => {
    if((!isPreviousAnsPresent)) {
      dispatch(
        notify({
          message: intl.formatMessage(messages.missingResponse)
        })
      )
      history.push(paths.fileUpload)
    }
  }, [dispatch, history, intl, isPreviousAnsPresent])

  return (
    <Page
      title={
        <FormattedMessage
          id='FileUploadStep2.Title'
          defaultMessage='Upload your file'
        />
      }
    >
      <FileUploadTitle />
      <Paper>
        <div className='mt3'>
          <Label>
            <FormattedMessage
              id='FileUploadStep2.Description'
              defaultMessage='Please review the following checklist to ensure that the data provided is in the correct format with all mandatory information. This will ensure a quicker turnaround time and better match results. Please confirm the following:'
            />
          </Label>
        </div>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justify='space-between'
          className='mt4'
        >
          <Grid item xs>
            <FormControlLabel
              label={
                <FormattedMessage
                  id='FileUploadStep2.q1WithLink'
                  defaultMessage={`Data has been provided in the correct format via TealBook {vendorMasterTemplate}`}
                  values={{
                    vendorMasterTemplate: (
                      <form
                        method='get'
                        action={`/downloads/TealBook Supplier Master And Spend Template.xlsx`}
                      >
                        <Button
                          variant='text'
                          autoSize
                          size='small'
                          type='submit'
                          className={classes.templateLink}
                        >
                          {intl.formatMessage(
                            messages.vendorMasterTemplateWithQuestionMark
                          )}
                        </Button>
                      </form>
                    )
                  }}
                />
              }
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q1'
                  checked={isCorrectFormat}
                  onChange={() => setIsCorrectFormat(!isCorrectFormat)}
                />
              }
            />
            <FormControlLabel
              label={intl.formatMessage(messages.isProvidedForNewSuppliers)}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q2'
                  checked={isProvidedForNewSuppliers}
                  onChange={() =>
                    setIsProvidedForNewSuppliers(!isProvidedForNewSuppliers)
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label={intl.formatMessage(messages.isAddressProvided)}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q3'
                  checked={isAddressProvided}
                  onChange={() => setIsAddressProvided(!isAddressProvided)}
                />
              }
            />
            <br />
            <FormControlLabel
              label={intl.formatMessage(messages.isIdProvided)}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q4'
                  checked={isIdProvided}
                  onChange={() => setIsIdProvided(!isIdProvided)}
                />
              }
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              label={intl.formatMessage(messages.isCompanyNameProvided)}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q5'
                  checked={isCompanyNameProvided}
                  onChange={() =>
                    setIsCompanyNameProvided(!isCompanyNameProvided)
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label={intl.formatMessage(messages.isTaxIdProvided)}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  name='q6'
                  checked={isTaxIdProvided}
                  onChange={() => setIsTaxIdProvided(!isTaxIdProvided)}
                />
              }
            />
            <br />
            {isSpendFileIncluded && (
              <FormControlLabel
                label={intl.formatMessage(messages.isSpendInUSD)}
                control={
                  <Checkbox
                    color='primary'
                    size='small'
                    name='q7'
                    checked={isSpendInUSD}
                    onChange={() => setIsSpendInUSD(!isSpendInUSD)}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
        <Label>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <FormattedMessage
              id='FileUploadStep2.Help'
              defaultMessage='If you have any questions please review the '
            />
            <form
              method='get'
              action={`/downloads/TealBook Supplier Master And Spend Template.xlsx`}
            >
              <Button
                variant='text'
                autoSize
                type='submit'
                className={classes.templateLink}
              >
                {intl.formatMessage(messages.vendorMasterTemplate)}
              </Button>
            </form>
            <FormattedMessage
              id='FileUploadStep2.VideoForGuidance'
              defaultMessage={` and <link>video for guidance</link>. If you still have questions please reach out to your CSM.`}
              values={{
                link: link => (
                  <ExternalLink
                    className='fw6 ml2'
                    href='https://rise.articulate.com/share/BxQHls0VyzG5rP3bl96_9uwfgGfcVzfz'
                  >
                    {link}
                  </ExternalLink>
                )
              }}
            />
          </Grid>
        </Label>
      </Paper>
      <Button
        className={classes.button}
        onClick={() => handleClickNextButton()}
        label={<FormattedMessage id='FileUpload.Next' defaultMessage='Next' />}
        disabled={!allRequiredQuestionsChecked}
      />
    </Page>
  )
}

export default FileUploadStep2
