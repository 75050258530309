import React, { FC, useState, FormEvent, useEffect } from 'react'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import { FormattedMessage, useIntl } from 'react-intl'
import Text from 'shared/components/Text'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from 'shared/components/Paper'
import { messages as dataQualityMessages } from './FileUploadStep2'
import { messages as businessContextMessages } from './FileUploadStep3'
import {
  BusinessContextAnswersType,
  DataQualityAnswersType,
  UploadFileKind
} from './FileUploadTab'
import { useHistory } from 'react-router'
import { defineMessages } from 'react-intl'
import { notify } from 'shared/actions'
import paths from 'admin/routes/paths'
import { useDispatch } from 'react-redux'

export const messages = defineMessages({
  isSpendFileIncluded: {
    id: 'FileUploadStep4.isSpendFileIncluded',
    defaultMessage: 'Has vendor master been provided with spend?'
  },
  missingResponse: {
    id: 'FileUploadStep4.missingResponse',
    defaultMessage: 'Responses are missing'
  }
})

type Props = {
  buyerType: string
  isBuyDataAdmin: boolean
  isUploading: boolean
  onUploadClientFile: ({
    file,
    questionnaireResponses,
    history,
    isSpendFileIncluded
  }) => void
  dataQualityAnswers: DataQualityAnswersType
  businessContextAnswers: BusinessContextAnswersType
  uploadFileKind: UploadFileKind
}

const FileUploadStep4: FC<Props> = ({
  isBuyDataAdmin,
  isUploading,
  onUploadClientFile,
  dataQualityAnswers,
  businessContextAnswers,
  uploadFileKind
}) => {
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [file, setFile] = useState<File | undefined>(undefined)
  const isSpendFileIncluded: boolean = uploadFileKind.isSpendFileIncluded

  useEffect(() => {
    if (
      (isSpendFileIncluded &&
        (!dataQualityAnswers?.modified || !businessContextAnswers?.modified)) ||
      (!isSpendFileIncluded && !dataQualityAnswers?.modified)
    ) {
      dispatch(
        notify({
          message: intl.formatMessage(messages.missingResponse)
        })
      )
      history.push(paths.fileUpload)
    }
  }, [
    businessContextAnswers,
    dataQualityAnswers,
    dispatch,
    history,
    intl,
    isSpendFileIncluded
  ])

  const handleFileChange = (file: File) => {
    setFile(file)
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (file) {
      const questionnaireResponses = generateQuestionnaireResponses()
      onUploadClientFile({
        file,
        questionnaireResponses,
        history,
        isSpendFileIncluded
      })
    }
    setFile(undefined)
  }

  const generateQuestionnaireResponses = () => {
    const userResponse: string[] = []
    const delimeter = ': '
    userResponse.push(
      dataQualityMessages.isCorrectFormat.defaultMessage +
        delimeter +
        (dataQualityAnswers.isCorrectFormat ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isProvidedForNewSuppliers.defaultMessage +
        delimeter +
        (dataQualityAnswers.isProvidedForNewSuppliers ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isAddressProvided.defaultMessage +
        delimeter +
        (dataQualityAnswers.isAddressProvided ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isIdProvided.defaultMessage +
        delimeter +
        (dataQualityAnswers.isIdProvided ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isCompanyNameProvided.defaultMessage +
        delimeter +
        (dataQualityAnswers.isCompanyNameProvided ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isTaxIdProvided.defaultMessage +
        delimeter +
        (dataQualityAnswers.isTaxIdProvided ? 'Yes' : 'No')
    )
    userResponse.push(
      dataQualityMessages.isSpendInUSD.defaultMessage +
        delimeter +
        (dataQualityAnswers.isSpendInUSD ? 'Yes' : 'No')
    )

    userResponse.push(
      businessContextMessages.refreshOrReset.defaultMessage +
        delimeter +
        businessContextAnswers.refreshOrReset
    )
    userResponse.push(
      businessContextMessages.dateRangeForData.defaultMessage +
        delimeter +
        businessContextAnswers.dateRangeForData
    )
    userResponse.push(
      businessContextMessages.dateRangeForDeletion.defaultMessage +
        delimeter +
        businessContextAnswers.dateRangeForDeletion
    )
    userResponse.push(
      businessContextMessages.spendCategory.defaultMessage +
        delimeter +
        businessContextAnswers.spendCategory
    )
    userResponse.push(
      businessContextMessages.businessCategory.defaultMessage +
        delimeter +
        businessContextAnswers.businessCategory
    )
    userResponse.push(
      messages.isSpendFileIncluded.defaultMessage +
        delimeter +
        (isSpendFileIncluded ? 'Yes' : 'No')
    )

    return userResponse
  }

  return isBuyDataAdmin ? (
    <Paper>
      <div className='mt3'>
        <Label>
          <FormattedMessage
            id='FileUploadStep4.FileLabel'
            defaultMessage='Please upload your file'
          />
        </Label>
      </div>
      <form onSubmit={handleSubmit}>
        {!isUploading ? (
          <>
            <FileInput
              accept='.csv, .xlsx, .xls'
              limit={1000}
              onChange={handleFileChange}
              aria-label='file-input'
            />
            <Button
              label={
                <FormattedMessage
                  id='FileUploadStep4.UploadFile'
                  defaultMessage='Upload File'
                />
              }
              type='submit'
              autoSize
              className='mt3'
              disabled={file === undefined || file === null}
            />
          </>
        ) : (
          <>
            <LinearProgress />
            <Text>
              <FormattedMessage
                id='FileUploadStep4.InProgress'
                defaultMessage='Upload in progress...'
              />
            </Text>
          </>
        )}
      </form>
    </Paper>
  ) : null
}

export default FileUploadStep4
