import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const uploadProfilePicture = createAction(
  actionTypes.UPLOAD_PROFILE_PICTURE
)

export const saveCompanyInfo = createAction(actionTypes.SAVE_COMPANY_INFO)
export const setRecordAsFrozen = createAction(actionTypes.SET_RECORD_AS_FROZEN)
export const saveOtherCompanyInfo = createAction(
  actionTypes.SAVE_OTHER_COMPANY_INFO
)
export const updateTags = createAction(actionTypes.UPDATE_TAGS)
export const addTags = createAction(actionTypes.ADD_TAGS)
export const removeTags = createAction(actionTypes.REMOVE_TAGS)
export const updateParentOrg = createAction(actionTypes.UPDATE_PARENT_ORG)
export const deleteLogo = createAction(actionTypes.DELETE_LOGO)
export const deletePitchDeck = createAction(actionTypes.DELETE_PITCHDECK)

export const startEditingCompanyHeader = createAction(
  actionTypes.START_EDITING_COMPANY_HEADER
)
export const stopEditingCompanyHeader = createAction(
  actionTypes.STOP_EDITING_COMPANY_HEADER
)

export const editContact = createAction(actionTypes.EDIT_CONTACT)
export const updateContact = createAction(actionTypes.UPDATE_CONTACT)
export const removeContact = createAction(actionTypes.REMOVE_CONTACT)
type RemoveAllContactsPayloads = Array<{ user: string }>
export const removeAllContacts = createAction<RemoveAllContactsPayloads>(
  actionTypes.REMOVE_ALL_CONTACTS
)
export const selectUserForContact = createAction(
  actionTypes.SELECT_USER_FOR_CONTACT
)
export const selectNewUserForContact = createAction(
  actionTypes.SELECT_NEW_USER_FOR_CONTACT
)
export const cancelAddingContact = createAction(
  actionTypes.CANCEL_ADDING_CONTACT
)
export const removeCertificate = createAction(actionTypes.REMOVE_CERTIFICATION)
export const updateCertificate = createAction(actionTypes.UPDATE_CERTIFICATION)

export const startCompanyEditMode = createAction('DEPRECATED_ACTION')
export const startCompanyReadMode = createAction('DEPRECATED_ACTION')

export const addCustomer = createAction(actionTypes.ADD_CUSTOMER)
export const removeCustomer = createAction(actionTypes.REMOVE_CUSTOMER)
export const getVerified = createAction(actionTypes.GET_VERIFIED)
export const openNewCustomerDialog = createAction(
  actionTypes.OPEN_NEW_CUSTOMER_DIALOG
)
export const closeNewCustomerDialog = createAction(
  actionTypes.CLOSE_NEW_CUSTOMER_DIALOG
)
export const openGetVerifiedDialog = createAction(
  actionTypes.OPEN_GET_VERIFIED_DIALOG
)
export const closeGetVerifiedDialog = createAction(
  actionTypes.CLOSE_GET_VERIFIED_DIALOG
)

export const removeBackgroundPicture = createAction(
  actionTypes.REMOVE_BACKGROUND_PICTURE
)

export const uploadFile = createAction(actionTypes.UPLOAD_FILE)

export const addDomain = createAction(actionTypes.ADD_DOMAIN)
export const addSuggestedTags = createAction(actionTypes.ADD_SUGGESTED_TAGS)

export const addClassificationCode = createAction(
  actionTypes.ADD_CLASSIFICATION_CODE
)
export const removeClassificationCode = createAction(
  actionTypes.REMOVE_CLASSIFICATION_CODE
)

export const closeCongratsSurveyDialog = createAction(
  actionTypes.CLOSE_CONGRATS_SURVEY_DIALOG
)
